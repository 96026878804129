import { createTheme } from '@mui/material';

const DEEP_JUNGLE_GREEN = '#207251';
const VIVID_AMBER = '#f6af0c';
const DARKER_VIVID_AMBER = '#d59a0a';
const DARK_GRAY = '#3A3A3A';

export const hexToRGBA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const theme = createTheme({
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    secondaryFontFamily: "'Playfair Display', serif",
    fontWeightBold: '600',
    fontWeightLight: '300',
    fontWeightMedium: '400',
    fontWeightRegular: '500',
    fontSize: 15,
  },
  palette: {
    primary: {
      main: DEEP_JUNGLE_GREEN,
    },
    secondary: {
      main: VIVID_AMBER,
      dark: DARKER_VIVID_AMBER,
    },
    text: {
      primary: DARK_GRAY,
    },
    // Add other customizations here
  }
});

export default theme;
