import React, { lazy, Suspense, useState } from 'react';
import './Home.css';
import MenuItem from '../../components/menuItem';
import ConsultationForm from '../../components/consultationForm';
import SEO from '../../components/SEO';
import { WEBSITE_URL } from '../../constants/config';

const ServicesOverview = lazy(() => import('../../components/servicesOverview'));
const AboutOverview = lazy(() => import('../../components/aboutOverview'));
const CallToAction = lazy(() => import('../../components/callToAction'));

const Home = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false); // New state for controlling the dialog

    const handleDialogOpen = () => {
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    return (
        <div className="main-content">
            <SEO
                title="Generations - Mental Health and Wellness"
                url={WEBSITE_URL}
            />
            <div className="background-image">
                {/* <img src={Logo} alt="Generations Logo" className="logo-over-background" /> */}
                <div className='request-button'>
                    <MenuItem
                        label="Request Consultation"
                        onClick={handleDialogOpen}
                        variation="button"
                        customStyles={{
                            fontSize: '1.2em',
                            padding: '12px 24px'
                        }}
                    />
                </div>
            </div>
            <Suspense fallback={<div className="loading">Loading...</div>}>
                <AboutOverview />
                <ServicesOverview />
                <CallToAction />
            </Suspense>
            <ConsultationForm open={isDialogOpen} handleClose={handleDialogClose} />
        </div>
    );
};

export default Home;
