import React from 'react';
import TextField from '@mui/material/TextField';
import MaskedInput from 'react-text-mask';

const PhoneNumberInput = (props) => {
  const { value, onChange, error, helperText } = props;

  const phoneMask = [
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  return (
    <MaskedInput
      mask={phoneMask}
      value={value}
      onChange={onChange}
      guide={false}
      placeholderChar={'\u2000'}
      render={(ref, props) => (
        <TextField
          {...props}
          required
          inputRef={ref}
          error={error}
          helperText={helperText}
          fullWidth
          margin="dense"
          id="phone-number"
          label="Phone Number"
          type="tel"
          variant="outlined"
        />
      )}
    />
  );
};

export default PhoneNumberInput;
