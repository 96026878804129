import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, url }) => {
  const defaultDescription = "Generations - A Mental Health and Wellness center located in Seattle.";

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description || defaultDescription} />
      <link rel="canonical" href={url || "https://www.generationsmhw.com"} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url || "https://www.generationsmhw.com"} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description || defaultDescription} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url || "https://www.generationsmhw.com"} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description || defaultDescription} />
    </Helmet>
  );
};

export default SEO;
