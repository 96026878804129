import React, { useState } from 'react';
import Logo from '../../assets/logos/Generations_With_Subtitle3.png';
import './TopMenu.css';
import MenuItem from '../../components/menuItem';
import { ABOUT_URL, HOME_URL, PATIENT_PORTAL_URL, RESOURCES_URL } from '../../constants/apiEndpoints';
import ConsultationForm from '../../components/consultationForm';
import { Link } from 'react-router-dom';


const TopMenu = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false); // New state for controlling the dialog

    const handleDialogOpen = () => {
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
    };    

    return (
        <div className="top-menu">
            <Link to='/'>
                <img src={Logo} alt="Generations" className="logo" style={{ cursor: 'pointer' }} />
            </Link>
            <div className="menu-items">
                <MenuItem label="Home" to={HOME_URL} />
                <MenuItem label="Our Providers" to={ABOUT_URL} />
                <MenuItem label="Resources" to={RESOURCES_URL} />
                <MenuItem label="Patient Portal" to={PATIENT_PORTAL_URL} openInNewTab={true} />
                <MenuItem label="Request Consultation" onClick={handleDialogOpen} variation="button" />
            </div>
            <div className="hamburger-menu" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                <div className="hamburger-bar"></div>
                <div className="hamburger-bar"></div>
                <div className="hamburger-bar"></div>
            </div>
            <div className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
                <MenuItem label="Home" to={HOME_URL} onMenuItemClick={closeMobileMenu}/>
                <MenuItem label="Our Providers" to={ABOUT_URL} onMenuItemClick={closeMobileMenu}/>
                <MenuItem label="Resources" to={RESOURCES_URL} onMenuItemClick={closeMobileMenu}/>
                <MenuItem label="Patient Portal" to={PATIENT_PORTAL_URL} openInNewTab={true} onMenuItemClick={closeMobileMenu}/>
                <MenuItem label="Request Consultation" onClick={handleDialogOpen} variation="button" onMenuItemClick={closeMobileMenu}/>
            </div>
            <ConsultationForm open={isDialogOpen} handleClose={handleDialogClose} /> {/* Include the ConsultationForm */}
        </div>
    );
};

export default TopMenu;
