// Resources.js
import React from 'react';
import { useTheme, Typography } from '@mui/material';
import './Resources.css';
import CallToAction from '../../components/callToAction';
import SEO from '../../components/SEO';
import { WEBSITE_URL } from '../../constants/config';
import { RESOURCES_URL } from '../../constants/apiEndpoints';

const Resources = () => {
    const theme = useTheme();

    const resourcesTitleStyle = {
        fontSize: ' min(calc(2rem + 0.5vw), 32px)', // Base size is 2rem and will scale with viewport width
        whiteSpace: 'nowrap', // Ensures title remains on a single line
        textOverflow: 'ellipsis', // Ellipsis to show content is clipped
        marginBottom: '0',
        padding: '0 1rem', // Adding side padding to ensure text never hits the sides
        fontFamily: theme.typography.secondaryFontFamily
    };

    return (
        <div className="resources-content" style={{ backgroundColor: 'rgba(240, 240, 240, 1)' }}>
            <SEO
                title="Mental Health and Wellness Resources - Generations"
                description="Explore our selection of handpicked supplements and products for mental health and wellness at Generations Mental Health and Wellness in Seattle."
                url={WEBSITE_URL + RESOURCES_URL}
            />
            <Typography variant="h1" style={resourcesTitleStyle}>Wellness Resources</Typography>

            <div className="resources-description">
                <p>
                    Explore our handpicked selection of supplements and products for mental health and wellness at our FullScript store.
                </p>

            </div>

            <div className="resources-link" style={{ backgroundColor: theme.palette.secondary.light }}>
                <a
                    href="https://us.fullscript.com/welcome/generationsmhw?utm_medium=webreferral&utm_source=other&utm_campaign=abmwebbuttons_light_500x500.svg&signup_source=website_buttons"
                    target="_top"
                >
                    <img
                        src="https://assets.fullscript.com/buttons/light_500x500.svg"
                        alt="Order supplements through my FullScript store."
                    />
                </a>
            </div>
            <CallToAction />
        </div>
    );
};

export default Resources;
