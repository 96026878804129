import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, FormControl, Select, InputLabel, MenuItem, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import PhoneNumberInput from './phoneNumberInput';
import { send } from '@emailjs/browser';
import config from '../config/config.json';

const ConsultationForm = ({ open, handleClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(dayjs());
  const [birthSex, setBirthSex] = useState('');
  const [reasonForConsultation, setReasonForConsultation] = useState('');
  const [insuranceName, setInsuranceName] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [honeypot, setHoneypot] = useState('');
  const [attemptedSubmission, setAttemptedSubmission] = useState(false);

  const handleSubmit = () => {
    if (honeypot) {
      console.warn('Possible bot detected. Form submission prevented.');
      return;
    }

    setAttemptedSubmission(true);

    if (!firstName || !lastName || !phoneNumber || !email || !birthSex || !reasonForConsultation || !insuranceName) {
      alert("Please fill in all required fields.");
      return;
    }

    send(
      config.EMAILJS.service_id,
      config.EMAILJS.template_id,
      {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        dateOfBirth: dateOfBirth.format('MMMM DD, YYYY'),
        reason: reasonForConsultation,
        insuranceName: insuranceName,
        additionalInfo: additionalInfo
      },
      config.EMAILJS.user_id
    )
      .then((response) => {
        if (response.status === 200) {
          setFormSubmitted(true);
        }
      })
      .catch((error) => {
        console.error("Error sending message: ", error);
        alert("There was an error sending your message. Please reach out via email or phone");
      });

    setFormSubmitted(true);
  };

  const resetForm = () => {
    setFirstName('');
    setLastName('');
    setPhoneNumber('');
    setEmail('');
    setDateOfBirth(dayjs());
    setBirthSex('');
    setReasonForConsultation('');
    setInsuranceName('');
    setAdditionalInfo('');
    setFormSubmitted(false);
    setAttemptedSubmission(false);
    handleClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={open} onClose={handleClose} aria-labelledby="consultation-form-dialog" fullWidth>
        <DialogTitle id="consultation-form-dialog">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            Request a Consultation
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {formSubmitted ? (
            <Typography variant="subtitle1">Thank you for requesting a consultation. Someone from our team will reach out to you shortly.</Typography>
          ) : (
            <form>
              <TextField
                required
                error={!firstName && attemptedSubmission}
                helperText={!firstName && attemptedSubmission && "This field is required."}
                autoFocus
                margin="dense"
                id="first-name"
                label="First Name"
                type="text"
                fullWidth
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                required
                error={!lastName && attemptedSubmission}
                helperText={!lastName && attemptedSubmission && "This field is required."}
                margin="dense"
                id="last-name"
                label="Last Name"
                type="text"
                  fullWidth
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <PhoneNumberInput
                  error={!phoneNumber && attemptedSubmission}
                  helperText={!phoneNumber && attemptedSubmission && "This field is required."}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <TextField
                  required
                  error={!email && attemptedSubmission}
                  helperText={!email && attemptedSubmission && "This field is required."}
                  margin="dense"
                id="email-address"
                label="Email Address"
                type="email"
                fullWidth
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <MobileDatePicker
                required
                label="Date of Birth"
                inputFormat="MM/DD/YYYY"
                value={dateOfBirth}
                onChange={(date) => setDateOfBirth(date)}
                renderInput={(params) => <TextField required error={!dateOfBirth && attemptedSubmission} fullWidth margin="dense" variant="outlined" {...params} />}
                minDate={dayjs().subtract(200, 'year').toDate()}
                maxDate={dayjs().toDate()}
              />

              <FormControl required fullWidth variant="outlined" margin="dense">
                <InputLabel htmlFor="birth-sex">Birth Sex</InputLabel>
                <Select
                  label="Birth Sex"
                  required
                  error={!birthSex && attemptedSubmission}
                  id="birth-sex"
                  value={birthSex}
                  onChange={(e) => setBirthSex(e.target.value)}
                >
                  <MenuItem value="" disabled>
                    Select An Option
                  </MenuItem>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>

              <TextField
                required
                error={!reasonForConsultation && attemptedSubmission}
                helperText={!reasonForConsultation && attemptedSubmission && "This field is required."}
                margin="dense"
                id="reason-for-consultation"
                label="Reason for Consultation"
                type="text"
                fullWidth
                variant="outlined"
                value={reasonForConsultation}
                onChange={(e) => setReasonForConsultation(e.target.value)}
              />

              <TextField
                required
                error={!insuranceName && attemptedSubmission}
                helperText={!insuranceName && attemptedSubmission && "This field is required."}
                margin="dense"
                id="insurance-name"
                label="Insurance Name"
                type="text"
                fullWidth
                variant="outlined"
                value={insuranceName}
                onChange={(e) => setInsuranceName(e.target.value)}
              />

              <Typography variant="caption" display="block" gutterBottom>
                If you don't have insurance, simply write "N/A".
              </Typography>

              <TextField
                margin="dense"
                id="anything-else"
                label="Anything else you would like us to know?"
                type="text"
                fullWidth
                multiline
                variant="outlined"
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
              />
              <input type="text" name="honeypot" style={{ display: 'none' }} value={honeypot} onChange={(e) => setHoneypot(e.target.value)} />

            </form>
          )}
        </DialogContent>
        <DialogActions>
          {formSubmitted ? (
            <Button onClick={resetForm} color="primary">
              Close
            </Button>
          ) : (
            <>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                sx={{
                  backgroundColor: 'secondary.main',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'secondary.dark',
                  },
                }}
              >
                Submit
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default ConsultationForm;
