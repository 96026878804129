import React from 'react';
import { Button, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ConsultationForm from './consultationForm';
import { EMAIL, FAX_NUMBER, PHONE_NUMBER } from '../constants/contact';

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: 'rgba(240, 240, 240, 0.5)',
  padding: '20px 0',
    paddingTop: '0',
  },
  title: {
    textAlign: 'center',
    fontFamily: theme.typography.secondaryFontFamily,
    paddingBottom: '20px',
    marginTop: '20px'
  },
  button: {
    marginTop: '0',
  },
  icon: {
    margin: '0 10px',
    color: '#777',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  contactInfo: {
    marginTop: '20px',
    textAlign: 'center',
  },
  gradientLine: {
    height: '4px',
    backgroundImage: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    width: '100%'
  },
  waveDesign: {
    width: '100%',
    backgroundColor: theme.palette.primary.main, // or any other color you wish
  }
}));

const CallToAction = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [emailHover, setEmailHover] = React.useState(false);
  const [phoneHover, setPhoneHover] = React.useState(false);
  const [faxHover, setFaxHover] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false); // New state for controlling the dialog

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const linkStyle = (isHovered) => ({
    textDecoration: 'none',
    color: isHovered ? theme.palette.primary.main : 'inherit',
  });

  return (
    <section className={classes.section}>
      <div className={classes.gradientLine}></div>
      {/* <svg className={classes.waveDesign} viewBox="0 0 500 30" preserveAspectRatio="none">
    <path d="M0.00,7.49 C150.00,22.49 349.20,-7.49 500.00,7.49 L500.00,30.00 L0.00,30.00 Z" fill="rgba(240, 240, 240, 0.5)"></path>
  </svg> */}
      <Typography variant="h4" className={classes.title}>
        Take the First Steps Towards Mental Wellness
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleDialogOpen}
      >
        Contact Us
      </Button>
      <div className={classes.contactInfo}>
        <Typography variant="body2" component="span">Email: </Typography>
        <Typography variant="body2" component="span">
          <a
            href={`mailto:${EMAIL}`}
            style={linkStyle(emailHover)}
            onMouseEnter={() => setEmailHover(true)}
            onMouseLeave={() => setEmailHover(false)}
          >
            {EMAIL}
          </a>
        </Typography>
      </div>
      <div>
        <Typography variant="body2" component="span">Phone: </Typography>
        <Typography variant="body2" component="span">
          <a
            href={`tel:${PHONE_NUMBER}`}
            style={linkStyle(phoneHover)}
            onMouseEnter={() => setPhoneHover(true)}
            onMouseLeave={() => setPhoneHover(false)}
          >
            {PHONE_NUMBER}
          </a>
        </Typography>
      </div> 
      <div>
        <Typography variant="body2" component="span">Fax: </Typography>
        <Typography variant="body2" component="span">
          <a
            href={`tel:${FAX_NUMBER}`}
            style={linkStyle(faxHover)}
            onMouseEnter={() => setFaxHover(true)}
            onMouseLeave={() => setFaxHover(false)}
          >
            {FAX_NUMBER}
          </a>
        </Typography>

      </div>
      <Typography variant="caption" className={classes.copyright}>
        © 2023 Generations Mental Health and Wellness PLLC
      </Typography>
      <ConsultationForm open={isDialogOpen} handleClose={handleDialogClose} /> {/* Include the ConsultationForm */}
    </section>
  );
};

export default CallToAction;
