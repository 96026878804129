import { useTheme } from '@mui/material';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { hexToRGBA } from '../theme/theme';

const MenuItem = ({ label, to, variation = 'normal', onClick, customStyles = {}, openInNewTab = false, onMenuItemClick }) => {
    const theme = useTheme();
    const location = useLocation();
    const [isHovering, setIsHovering] = useState(false);

    const commonStyles = {
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.fontSize,
        cursor: 'pointer',
        margin: '0 15px',
        padding: '0 5px',
        color: theme.palette.text.primary,
        textDecoration: 'none'
    };

    const buttonStyles = {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        borderRadius: '50px',
    };

    const activeStyles = {
        color: theme.palette.primary.main
    };

    const hoveringStyles = variation === 'button'
        ? { backgroundColor: theme.palette.secondary.dark }
        : { color: hexToRGBA(theme.palette.primary.main, 0.8) };

    const menuItemStyle = variation === 'button'
        ? { ...commonStyles, ...buttonStyles, ...customStyles }
        : { ...commonStyles, ...customStyles };

    const isActive = location.pathname === to;

    const handleMouseEnter = () => setIsHovering(true);
    const handleMouseLeave = () => setIsHovering(false);

    const handleItemClick = () => {
        if (onClick) {
            onClick();
        }
        if (onMenuItemClick) {
            onMenuItemClick();
        }
    
        // Ensure the page is scrolled to the top
        window.scrollTo(0, 0);
    };

    const menuItemContent = (
        <div
            style={{
                ...menuItemStyle,
                ...(isHovering ? hoveringStyles : {}),
                ...(isActive ? activeStyles : {})
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleItemClick} // Use the new handler here}
        >
            {label}
        </div>
    );

    return to ? (
        <Link to={to} style={{ textDecoration: 'none' }} rel="noreferrer" target={openInNewTab ? '_blank' : undefined}>
            {menuItemContent}
        </Link>
    ) : (
        <a href={to} style={{ textDecoration: 'none' }} rel="noreferrer" target={openInNewTab ? '_blank' : undefined}>
            {menuItemContent}
        </a>
    );
};

export default MenuItem;
