import React from 'react';
import './About.css';
import Headshot from '../../assets/images/JonC2.jpg';
import { useTheme, Typography } from '@mui/material';
import { hexToRGBA } from '../../theme/theme';
import CallToAction from '../../components/callToAction';
import SEO from '../../components/SEO';
import { WEBSITE_URL } from '../../constants/config';
import { ABOUT_URL } from '../../constants/apiEndpoints';

const About = () => {
    const theme = useTheme();

    const ourProvidersTitleStyle = {
        fontSize: 'min(calc(2rem + 0.5vw), 32px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: '15px',
        marginTop: '20px',
        padding: '0 1rem',
        fontFamily: theme.typography.secondaryFontFamily
    };

    return (
        <div className="main-content">
            <SEO
                title="About Ionatan Cauneac - Generations Mental Health and Wellness"
                description="Meet Ionatan Cauneac, a board-certified Psychiatric Mental Health Nurse Practitioner at Generations. Learn about his experience, philosophy, and commitment to mental health and wellness."
                url={WEBSITE_URL + ABOUT_URL}
            />
            <Typography 
                variant="h1"
                className="our-providers-title" 
                style={ourProvidersTitleStyle}>
                Meet Our Providers
            </Typography>
            <Typography variant="h5" style={{ marginBottom: '0', fontFamily: theme.typography.secondaryFontFamily }}>
                Ionatan Cauneac
            </Typography>
            <Typography variant="div" style={{ marginTop: '0', fontFamily: theme.typography.fontFamily, fontSize: '12px' }}>
                ARNP, PMHNP-BC
            </Typography>

            <div className="headshot-container">
                <img src={Headshot} alt="Jonathan Cauneac" className="headshot" />
            </div>

            <div className="description">
                <p>
                    Ionatan Cauneac is a Board Certified Psychiatric-Mental Health Nurse Practitioner (PMHNP-BC) who specializes in psychiatric assessments, medication management, and supportive psychotherapy for children, adolescents, and adults. He obtained his Master’s degree from the University of San Francisco and psychiatric specialty training from the Rocky Mountain University of Health Sciences in Provo, Utah. He now practices medicine in the greater Seattle, Washington area.
                </p>

            </div>

            <div className="description">
                <p>
                    Some of the mental health problems he treats include, but are not limited to depression, anxiety, bipolar spectrum disorders, obsessions and compulsions, insomnia, trauma, and ADHD. Rather than mask or cover your symptoms with medication, his goal is to take a wholistic approach to your care and address the root cause of your mental health challenges so that you can begin the process of healing. He is a firm believer that without mental health you can never experience true health and wellness.
                </p>
                <p>
                    Ionatan has experience working in a variety of different settings, including outpatient care, critical care, addiction medicine, jail health, and various acuities within inpatient psychiatric facilities. All these experiences have given him a unique perspective, knowledge, and insight to support your mental health care needs.
                </p>
                <p>
                    He believes in individualized care and tailors all recommended treatments to match you and your unique situation. His approach is one of empathy and genuine human connection. His goal is to hear and understand you, inform you of available treatment options, and celebrate your victories as your mental health improves.
                </p>
            </div>

            <div className="message-from-ionatan" style={{ backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.2) }}>
                <Typography variant="h4" style={{ color: theme.palette.primary.main, fontFamily: theme.typography.secondaryFontFamily }}>
                    A Message From Ionatan
                </Typography>
                <blockquote>
                    <p>"Oftentimes, we can become so hard-pressed by our mental health issues that we begin to feel hopeless and no longer remember what it's like to feel ‘normal' again. Whatever your struggles are, I believe there is still hope and healing available for you.</p>
                    <p>The road to mental wellness may be long and tumultuous, or it can be a small change that makes a big difference. Whatever it is, I will be your confidant and will come alongside you in this process to listen, advise, and empower you to become and remain the best version of yourself.</p>
                    <p>I look forward to working with you to improve your mental wellness and to achieve optimal mental and physical health.”</p>
                </blockquote>
            </div>

            <CallToAction />
        </div>
    );
};

export default About;
