import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import theme from './theme/theme.js';
import TopMenu from './pages/TopMenu/TopMenu.js';
import Home from './pages/Home/Home.js';
import About from './pages/About/About.js';
import { ABOUT_URL, HOME_URL, RESOURCES_URL } from './constants/apiEndpoints.js';
import Resources from './pages/Resources/Resources.js';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <TopMenu />
        <div>
          <Routes>
            <Route path={HOME_URL} element={<Home />} />
            <Route path={ABOUT_URL} element={<About />} />
            <Route path={RESOURCES_URL} element={<Resources />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
